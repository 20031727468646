import {
  Dialog, DialogTitle, List, ListItem, ListItemText,
} from '@mui/material';
import useMenu from 'menu-actions/useMenu';
import React, { useCallback, useEffect, useState } from 'react';
import { useFirestore, useSigninCheck, useUser } from 'reactfire';
import { useSignIn } from 'useSignIn/SingInContext';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import useGlobal from 'global-state/store';
import { useTranslation } from 'react-i18next';
import DownloadIcon from '@mui/icons-material/Download';
import { deserializeFormValues, serializeFormValues } from './formikPersistence';
import { minimalInitialValues } from './minimalInitialValues';

export default function SavedFormsDialog({
  formik, open, setOpen, docName,
}) {
  const { dispatchMenuActions } = useMenu();
  const firestore = useFirestore();
  const { data: user } = useUser();
  const { data: signInCheckResult } = useSigninCheck();
  const { openSignin } = useSignIn();
  const [, globalActions] = useGlobal();
  const { t } = useTranslation();
  const [currentForms, setCurrentForms] = useState([]);

  const clearFormStorage = () => {
    sessionStorage.removeItem('saleForm');
  };

  const applyMinimalValues = useCallback(() => {
    formik.setValues(deserializeFormValues(minimalInitialValues));
  }, [formik]);

  const handleSaveForm = useCallback(async () => {
    if (!(signInCheckResult?.signedIn === true)) {
      openSignin();
      return;
    }
    const serializedValues = JSON.stringify(serializeFormValues(formik.values));

    try {
      const docRef = doc(firestore, 'users', user.uid, 'private', docName);
      const docSnap = await getDoc(docRef);

      let forms = [];
      if (docSnap.exists()) {
        forms = docSnap.data().forms || [];
      }

      forms.push({
        name: new Date().toLocaleString(),
        timestamp: new Date(),
        formValues: serializedValues,
      });

      if (forms.length > 6) {
        forms.shift();
      }

      await setDoc(docRef, { forms });

      globalActions.setSnackbarMessage({ message: t('success'), severity: 'success' });
    } catch (error) {
      globalActions.setSnackbarMessage({ message: `${t('unexpected_error')} ${error}`, severity: 'error' });
    }
  }, [docName, firestore, formik.values, globalActions, openSignin, signInCheckResult?.signedIn, t, user?.uid]);

  const handleReloadForm = useCallback(async () => {
    try {
      const docRef = doc(firestore, 'users', user.uid, 'private', docName);
      const docSnap = await getDoc(docRef);

      let forms = [];
      if (docSnap.exists()) {
        forms = docSnap.data().forms || [];
      }

      forms.sort((a, b) => b.timestamp - a.timestamp);

      setCurrentForms(forms);
      setOpen(true);
    } catch (error) {
      globalActions.setSnackbarMessage({ message: `${t('unexpected_error')} ${error}`, severity: 'error' });
    }
  }, [firestore, globalActions, setOpen, t, user?.uid, docName]);

  const handleFormSelection = (selectedForm) => {
    const deserializedValues = deserializeFormValues(JSON.parse(selectedForm.formValues));
    formik.setValues(deserializedValues);
    setOpen(false);
  };

  useEffect(() => {
    const applyMinimalValuesAction = {
      id: 'apply_minimal_values',
      name: t('minimal_exemple'),
      callback: applyMinimalValues,
    };
    const resetFormAction = {
      id: 'reset_form',
      name: t('reset'),
      callback: () => {
        clearFormStorage();
        formik.resetForm();
      },
    };
    const saveFormAction = {
      id: 'save_form',
      name: t('save'),
      callback: handleSaveForm,
    };
    const reloadFormAction = {
      id: 'reload_form',
      name: t('reload'),
      callback: handleReloadForm,
    };
    dispatchMenuActions({
      type: 'add',
      actions: [saveFormAction, reloadFormAction, applyMinimalValuesAction, resetFormAction],
    });

    return () => {
      dispatchMenuActions({
        type: 'clear',
      });
    };
  }, [applyMinimalValues, dispatchMenuActions, formik, handleReloadForm, handleSaveForm, t]);

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xs" fullWidth>
      <DialogTitle>{t('saves')}</DialogTitle>
      <List>
        {currentForms.map((form) => (
          <ListItem button onClick={() => handleFormSelection(form)} key={form.name} sx={{ gap: 2 }}>
            <ListItemText primary={form.name} />
            <DownloadIcon />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

export const saveFormToFirestore = async (nameOfDoc, formikValues, userUid, firestore) => {
  const serializedValues = JSON.stringify(serializeFormValues(formikValues));
  const docRef = doc(firestore, 'users', userUid, 'private', nameOfDoc);
  const docSnap = await getDoc(docRef);

  let forms = [];
  if (docSnap.exists()) {
    forms = docSnap.data().forms || [];
  }

  forms.push({
    name: new Date().toLocaleString(),
    timestamp: new Date(),
    formValues: serializedValues,
  });

  if (forms.length > 6) {
    forms.shift();
  }

  await setDoc(docRef, { forms });
};
