import React, { useState, useEffect } from 'react';
import {
  doc, getDoc,
} from 'firebase/firestore';
import { useFirestore, useUser } from 'reactfire';
import {
  Box, Button, CircularProgress, List, ListItem, Typography,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { AccountCircle } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useSignIn } from 'useSignIn/SingInContext';
import useGlobal from 'global-state/store';
import { getAnalytics, logEvent } from 'firebase/analytics';
import AddIcon from '@mui/icons-material/Add';
import ContractListItem from './ContractListItem';

function MyContracts() {
  const { data: user } = useUser();
  const { t } = useTranslation();
  const { openSignin } = useSignIn();
  const [, globalActions] = useGlobal();
  const analytics = getAnalytics();

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);

    if (queryParameters.get('canceled')) {
      globalActions.setSnackbarMessage({ message: t('subscriptions.order_canceled'), severity: 'error' });
      logEvent(analytics, 'subscriptions_checkout_canceled', {
        appName: 'Autonoom Web',
      });
    }
  }, [analytics, globalActions, t]);

  if (!user) {
    return (
      <Button
        variant="outlined"
        color="inherit"
        size="small"
        component={Link}
        onClick={() => openSignin()}
        startIcon={<AccountCircle />}
      >
        {t('signin.login')}
      </Button>
    );
  }

  return <MyContractsList user={user} />;
}

function MyContractsList({ user }) {
  const [contracts, setContracts] = useState([]);
  const [doneContracts, setDoneContracts] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const firestore = useFirestore();
  const [, globalActions] = useGlobal();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchContracts = async () => {
      try {
        setLoading(true);

        // Fetch user's private data
        const privateDataDoc = await getDoc(doc(firestore, `/users/${user.uid}/private/data`));
        if (!privateDataDoc.exists()) {
          throw new Error(`Document not found: ${privateDataDoc.ref.path}`);
        }
        const userContractsIds = privateDataDoc.data().onGoingContracts;

        // Fetch each contract
        const contractsPromises = userContractsIds.map(
          (contractId) => getDoc(doc(firestore, `/autonoomContracts/${contractId}`)),
        );
        const contractsDocs = await Promise.all(contractsPromises);

        const contractsData = contractsDocs.map((document) => ({ id: document.id, ...document.data() }));

        const doneContractsData = contractsData.filter(
          (contract) => contract.state === 'contractSuccessfullyGenerated',
        );
        const ongoingContractsData = contractsData.filter(
          (contract) => contract.state !== 'contractSuccessfullyGenerated',
        );

        setContracts(ongoingContractsData);
        setDoneContracts(doneContractsData);
      } catch (err) {
        globalActions.setSnackbarMessage({ message: `${t('unexpected_error')} ${err.message}`, severity: 'error' });
        throw err;
      } finally {
        setLoading(false);
      }
    };

    fetchContracts();
  }, [firestore, globalActions, t, user.uid]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 1,
      backgroundColor: 'lightGray.main',
      width: '100vw',
      minHeight: '80vh',
      mt: { xs: -10, md: -12 },
      pt: { xs: 10, md: 12 },
      px: 1,
      pb: 5,
    }}
    >
      {contracts.length > 0 && (
      <>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{t('my_contracts')}</Typography>

        <List sx={{ maxWidth: 800 }}>
          {contracts.map((item, index) => (
            <ListItem
              key={item.id}
            >
              <ContractListItem
                itemData={item}
                onClick={() => { navigate('contract', { state: { contract: item } }); }}
              />
            </ListItem>
          ))}
        </List>
      </>
      )}

      {doneContracts.length > 0 && (
      <>
        <Typography variant="h6" sx={{ mt: 6, fontWeight: 'bold' }}>{t('my_finished_contracts')}</Typography>
        <List sx={{ maxWidth: 800 }}>
          {doneContracts.map((item, index) => (
            <ListItem
              key={item.id}
            >
              <ContractListItem
                itemData={item}
                onClick={() => { navigate('contract', { state: { contract: item } }); }}
              />
            </ListItem>
          ))}
        </List>
      </>
      )}

      <Button
        variant="outlined"
        sx={{ fontWeight: 'bold' }}
        endIcon={<AddIcon />}
        onClick={() => navigate('/vehicleSale')}
      >
        {t('start_contract')}
      </Button>
    </Box>
  );
}

export default MyContracts;
