import React from 'react';
import Box from '@mui/material/Box';
import {
  Routes,
  Route,
  useNavigate,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useGlobal from 'global-state/store';
import { getAnalytics } from 'firebase/analytics';
import {
  useFirestore,
  useSigninCheck,
} from 'reactfire';
import {
  addDoc,
  collection,
} from 'firebase/firestore';
import Contact from 'components/Contact';
import Welcome from 'components/Welcome';
import * as Sentry from '@sentry/react';
import MyAccount from './account/MyAccount';
import MyContracts from './myContracts/MyContracts';
import AcceptSaleInvitation from './saleInvitation/AcceptVehicleSale';
import ContractPage from './myContracts/ContractPage';
import BuyerForm from './vehicleSale/buyerForm/BuyerForm';
import SellerSaleFormalitiesForm from './vehicleSale/sellerSaleFormalitiesForm/SellerSaleFormalitiesForm';
import ContractPDFPage from './myContracts/ContractPDFPage';
import Admin from './admin/Admin';
import GeneratePDFSuccess from './myContracts/GeneratePDFSuccess';
import AutonoomPresentation from './AutonoomPresentation';
import VehicleSalePage from './vehicleSale/VehicleSalePage';
import QRCodes from './qrcodes/QRCodes';

export default function AutoRoutes() {
  const [, globalActions] = useGlobal();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const analytics = getAnalytics();
  const db = useFirestore();
  const { data: signInCheckResult } = useSigninCheck();

  const sendEmail = async (values) => {
    const emailContent = `Email: 
      ${values.email}

      Nom: 
      ${values.nameAndCompany}

      Message: 
      ${values.message}`;

    await addDoc(
      collection(db, 'mail'),
      {
        to: 'contact@autonoom.fr',
        message: {
          subject: `[Autonoom, Contact] ${values.nameAndCompany}`,
          text: emailContent,
        },
      },
    );
  };

  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

  return (
    <SentryRoutes>
      <Route path="/*" element={<AutonoomPresentation />} />
      <Route path="/alpha" element={<Welcome />} />
      <Route path="/account" element={<MyAccount />} />
      <Route path="/menuAccount" element={<MyAccount />} />
      <Route path="/vehicleSale" element={<VehicleSalePage />} />
      <Route path="/admin" element={<Admin />} />
      <Route
        path="contact"
        element={(
          <Contact
            t={t}
            navigate={navigate}
            globalActions={globalActions}
            analytics={analytics}
            sendEmail={sendEmail}
            signInCheckResult={signInCheckResult}
          />
        )}
      />
      <Route
        path="menuContact"
        element={(
          <Contact
            t={t}
            navigate={navigate}
            globalActions={globalActions}
            analytics={analytics}
            sendEmail={sendEmail}
            signInCheckResult={signInCheckResult}
          />
        )}
      />
      <Route path="/myContracts" element={<MyContracts />} />
      <Route path="/myContracts/contract" element={<ContractPage />} />
      <Route path="/myContracts/contract/acquirerForm" element={<BuyerForm />} />
      <Route path="/myContracts/contract/saleFormalitiesForm" element={<SellerSaleFormalitiesForm />} />
      <Route path="/acceptContractSharingInvitation" element={<AcceptSaleInvitation />} />
      <Route path="/contractPdf" element={<ContractPDFPage />} />
      <Route path="/generatePdfSuccess" element={<GeneratePDFSuccess />} />
      <Route path="/qrcodes/:qrCodeId" element={<QRCodes />} />
      <Route
        path="*"
        element={(
          <Box>
            <p>There is nothing here!</p>
          </Box>
        )}
      />
    </SentryRoutes>
  );
}
