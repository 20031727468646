import React, { useState } from 'react';
import { useFirestore, useFirestoreCollectionData, useFunctions } from 'reactfire';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { collection } from 'firebase/firestore';
import {
  Box, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import WarningIcon from '@mui/icons-material/Warning';
import { useNavigate } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';
import useGlobal from 'global-state/store';

export default function Contracts() {
  const firestore = useFirestore();
  const contractsRef = collection(firestore, 'autonoomContracts');
  const { data: contracts } = useFirestoreCollectionData(contractsRef, {
    idField: 'id',
  });
  const { t } = useTranslation();

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 300 },
    {
      field: 'vehicle',
      headerName: 'véhicule',
      width: 200,
      valueGetter: (value, row, column, apiRef) => row.ownerForm?.values?.vehicle?.model,

    },
    { field: 'ownerEmail', headerName: 'propriétaire', width: 200 },
    { field: 'sharedToEmail', headerName: 'Acquéreur', width: 200 },
    {
      field: 'submitDate',
      headerName: 'Démarrage',
      width: 200,
      valueGetter: (value, row, column, apiRef) => row.ownerForm?.submitDate?.toDate()?.toLocaleString(),
    },
    { field: 'state', headerName: 'Etat', width: 250 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 300,
      sortable: false,
      renderCell: (params) => <ActionCell params={params} />,

    },
  ];

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      height: 500,
      gap: 3,
      width: '95%',
    }}
    >

      <Typography variant="h4">{t('Les Contrats')}</Typography>
      <DataGrid rows={contracts || []} columns={columns} />

    </Box>
  );
}

function ActionCell({ params }) {
  const { state } = params.row;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const functions = useFunctions();
  functions.region = 'europe-west1';
  const contractCall = httpsCallable(functions, 'createContractPdfCall');
  const contractAndStateCall = httpsCallable(functions, 'createContractAndStateAndMailsCall');

  const [, globalActions] = useGlobal();
  const { t } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleGoToContract = async (contract) => {
    const storageDocPath = `gs://autonoom-ed849.appspot.com/autonoomContracts/${contract.id}/contrat-autonoom.pdf`;
    navigate('/contractPdf', {
      state: {
        contract,
        storageDocPath,
      },
    });
  };

  const handleContractCall = async () => {
    const contract = params.row;
    handleClose();
    setIsLoading(true);
    try {
      await contractCall({
        userUid: contract.owner,
        contractUid: contract.id,
      });

      handleGoToContract(contract);
    } catch (error) {
      globalActions.setSnackbarMessage({ message: `${t('unexpected_error')} ${error}`, severity: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleContractAndStateCall = async () => {
    const contract = params.row;
    handleClose();
    setIsLoading(true);
    try {
      await contractAndStateCall({
        userUid: contract.owner,
        contractUid: contract.id,
      });

      handleGoToContract(contract);
    } catch (error) {
      globalActions.setSnackbarMessage({ message: `${t('unexpected_error')} ${error}`, severity: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  if (state === 'sellerSaleFormalitiesCompleted' || state === 'contractSuccessfullyGenerated') {
    return (
      <div>
        <LoadingButton size="small" loading={isLoading} variant="outlined" color="primary" onClick={handleClick}>
          Actions
        </LoadingButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleContractCall}>
            <ListItemText
              primary="Générer contrat"
            />
            <Tooltip title={'Attention, cette action va remplacer ou ajouter le nouveau contrat généré au client. '
            + 'Cela est utile pour régénérer le contrat après une correction de bug par exemple.'}
            >
              <ListItemIcon>
                <WarningIcon color="warning" />
              </ListItemIcon>
            </Tooltip>
          </MenuItem>
          <MenuItem
            onClick={handleContractAndStateCall}
            sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
          >

            <ListItemText
              primary="Générer contrat - terminer dossier - envoyer emails"
            />
            <Tooltip title={'Attention, cette action va remplacer ou ajouter le nouveau contrat généré au client, '
            + 'changer l\'etat d\'avancement du dossier et envoyer les emails aux partie.'
            + 'Celui-ci ne verra donc plus le bouton pour accéder au paiement. Cela est utile lors d\'une '
            + 'erreur serveur après un paiement, ou pour régénérer le contrat après une correction de bug par exemple.'}
            >
              <ListItemIcon>
                <WarningIcon color="warning" />
              </ListItemIcon>
            </Tooltip>
          </MenuItem>
        </Menu>
      </div>
    );
  }
  return null; // No buttons for other states
}
