import React from 'react';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { collection } from 'firebase/firestore';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function Codes() {
  const firestore = useFirestore();
  const availableCodesRef = collection(firestore, 'availableCodes');
  const activatedCodesRef = collection(firestore, 'activatedCodes');
  const { data: availableCodes } = useFirestoreCollectionData(availableCodesRef);
  const { data: activatedCodes } = useFirestoreCollectionData(activatedCodesRef);
  const { t } = useTranslation();

  const columns: GridColDef[] = [
    { field: 'type', headerName: 'Type', flex: 1 },
    { field: 'id', headerName: 'ID', flex: 1 },
    {
      field: 'codeExpiryDate',
      headerName: 'Expiry Date',
      flex: 1,
      valueGetter: (value, row, column, apiRef) => row.codeExpiryDate?.toDate()?.toLocaleString(),
    },
    { field: 'activationsLeft', headerName: 'Activations Left', flex: 1 },
    { field: 'activationsUsed', headerName: 'Activations Used', flex: 1 },
  ];

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', height: 1000, gap: 3, width: '95%',
    }}
    >

      <Typography variant="h4">{t('available_codes')}</Typography>
      <DataGrid rows={availableCodes || []} columns={columns} />

      <Typography variant="h4">{t('activated_codes')}</Typography>
      <DataGrid rows={activatedCodes || []} columns={columns} />
    </Box>
  );
}
