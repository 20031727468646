import React from 'react';
import './App.css';
import 'fontsource-roboto';
import {
  ThemeProvider,
  StyledEngineProvider,
  styled,
  createTheme,
} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {
  useFirebaseApp,
  AuthProvider,
  AnalyticsProvider,
  FirestoreProvider,
  FirebaseAppProvider,
  FunctionsProvider,
  RemoteConfigProvider,
  useInitRemoteConfig,
  useInitFirestore,
  StorageProvider,
} from 'reactfire';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';
import { initializeFirestore } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getRemoteConfig, fetchAndActivate } from 'firebase/remote-config';
import { connectStorageEmulator, getStorage } from 'firebase/storage';
import LoadingPage from 'components/LoadingPage';
import { frFR } from '@mui/x-date-pickers/locales';
import { frFR as coreFrFR } from '@mui/material/locale';
import { initI18next } from './i18n/i18next';
import Home from './components/Home';
import 'moment/locale/fr';

const firebaseConfig = {
  apiKey: 'AIzaSyBYht6xfzT5RGcW0j3Zb3AxsTZnH2cQmrw',
  authDomain: 'autonoom.fr',
  projectId: 'autonoom-ed849',
  storageBucket: 'autonoom-ed849.appspot.com',
  messagingSenderId: '66337130865',
  appId: '1:66337130865:web:d09e26092db35c8f2fced0',
  measurementId: 'G-K04LPDL58L',
};

const theme = createTheme(
  {
    palette: {
      primary: {
        main: '#14c7bf',
        contrastText: 'white',
      },
      secondary: {
        main: '#4fde9c',
        contrastText: 'white',
      },
      info: {
        main: '#002b34',
      },
      text: {
        primary: '#09524e',
      },
      gold: {
        main: '#FFD700',
        contrastText: 'white',
      },
      darkgray: {
        main: '#0000008a',
        contrastText: 'white',
      },
      mediumgray: {
        main: '#8585858a',
        contrastText: 'white',
      },
      gray: {
        main: '#d8d8d8',
        contrastText: 'white',
      },
      lightGray: {
        main: '#f5f5f7',
        contrastText: 'black',
      },
      link: {
        main: '#109993',
        contrastText: 'white',
      },
    },
  },
  frFR,
  coreFrFR,
);

const GlobalStyle = styled('div')(({ theme: t }) => ({
  backgroundColor: t.palette.background.default,
  width: '100%',
  minHeight: '100vh',
  position: 'absolute',
  top: 0,
  left: 0,
}));

function AllReactFireProviders() {
  const app = useFirebaseApp();
  const auth = getAuth(app);
  const functionsInstance = getFunctions(app);
  const storageInstance = getStorage(app);
  if (process.env.NODE_ENV === 'development') {
    connectAuthEmulator(auth, 'http://localhost:9099');
    connectFunctionsEmulator(functionsInstance, 'localhost', 5001);
    connectStorageEmulator(storageInstance, 'localhost', 9199);
  }

  return (
    <AnalyticsProvider sdk={getAnalytics(app)}>
      <AuthProvider sdk={auth}>
        <FunctionsProvider sdk={functionsInstance}>
          <StorageProvider sdk={storageInstance}>
            <ProvidersWithLoading />
          </StorageProvider>
        </FunctionsProvider>
      </AuthProvider>
    </AnalyticsProvider>
  );
}

function ProvidersWithLoading(props) {
  const { status: firestoreStatus, data: firestoreInstance } = useInitFirestore(async (firebaseApp) => {
    const savedSetting = localStorage.getItem('forceLongPolling');
    const forceLongPolling = savedSetting === 'true';
    const baseSettings = {};

    if (forceLongPolling) {
      baseSettings.experimentalForceLongPolling = true;
    } else {
      baseSettings.experimentalAutoDetectLongPolling = true;
    }
    const fsSettings = (process.env.NODE_ENV === 'development')
      ? {
        ...baseSettings,
        host: 'localhost:4401',
        ssl: false,
      } : baseSettings;

    const db = initializeFirestore(firebaseApp, fsSettings);
    return db;
  });

  const { status: remoteConfigStatus, data: remoteConfigInstance } = useInitRemoteConfig(async (firebaseApp) => {
    const remoteConfig = getRemoteConfig(firebaseApp);
    remoteConfig.settings = {
      minimumFetchIntervalMillis: 600000,
      fetchTimeoutMillis: 600000,
    };

    try {
      await fetchAndActivate(remoteConfig);
    } catch (e) {
      console.warn('Remote config failed to be activated');
    }

    return remoteConfig;
  });

  if (firestoreStatus === 'loading' || remoteConfigStatus === 'loading') {
    return (
      <LoadingPage />
    );
  }

  return (

    <FirestoreProvider sdk={firestoreInstance}>
      <RemoteConfigProvider sdk={remoteConfigInstance}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <GlobalStyle>
              <>
                <CssBaseline />
                <Home />
              </>
            </GlobalStyle>
          </ThemeProvider>
        </StyledEngineProvider>
      </RemoteConfigProvider>
    </FirestoreProvider>

  );
}

function App() {
  initI18next();
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>

      <div className="App" style={{ whiteSpace: 'pre-line' }}>
        <AllReactFireProviders />
      </div>

    </FirebaseAppProvider>
  );
}

export default App;
