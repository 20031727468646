import React, { useRef, useState } from 'react';
import {
  Box, Button, Collapse, Paper, Step, StepButton, StepLabel, Stepper, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { httpsCallable } from 'firebase/functions';
import { useFunctions } from 'reactfire';
import { getAnalytics, logEvent } from 'firebase/analytics';
import useGlobal from 'global-state/store';
import { LoadingButton } from '@mui/lab';
import moment from 'moment';
import SharedBuyerForm from 'components/vehicleSale/buyerForm/SharedBuyerForm';
import SharedSaleForm from 'components/vehicleSale/sellerForm/SharedSaleForm';
import SharedSellerSaleFormalitiesForm
  from 'components/vehicleSale/sellerSaleFormalitiesForm/SharedSellerSaleFormalitiesForm';
import WaitingForInviteInfo from './WaitingForInviteInfo';

export default function VehicleSaleContract({ contract, user, setContract }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const functions = useFunctions();
  functions.region = 'europe-west1';
  const checkoutSession = httpsCallable(functions, 'stripeCheckoutSession');
  const contractCall = httpsCallable(functions, 'createContractPdfCall');
  const analytics = getAnalytics();
  const [, globalActions] = useGlobal();
  const [pageStatus, setPageStatus] = useState('initial');
  const ownerCollapse = useRef(null);
  const acquirerCollapse = useRef(null);
  const formalitiesCollapse = useRef(null);

  const expandIcon = (state) => (state ? <ExpandLessIcon /> : <ExpandMoreIcon />);

  const goToAcquirerForm = () => {
    navigate('acquirerForm', {
      state: {
        contract,
      },
    });
  };

  const goToOwnerCompleteSFForm = () => {
    navigate('saleFormalitiesForm', {
      state: {
        contract,
      },
    });
  };

  const handleContractCall = async () => {
    setPageStatus('loading');
    try {
      await contractCall({
        userUid: user.uid,
        contractUid: contract.id,
      });

      handleGoToContract();
    } catch (error) {
      globalActions.setSnackbarMessage({ message: `${t('unexpected_error')} ${error}`, severity: 'error' });
    } finally {
      setPageStatus('done');
    }
  };

  const handleGoToContract = async () => {
    const storageDocPath = `gs://autonoom-ed849.appspot.com/autonoomContracts/${contract.id}/contrat-autonoom.pdf`;
    navigate('/contractPdf', {
      state: {
        contract,
        storageDocPath,
      },
    });
  };

  const handleStripeCheckout = async () => {
    setPageStatus('loading');
    logEvent(analytics, 'clicked_checkout_session', {
      appName: 'Autonoom Web',
    });
    try {
      const result = await checkoutSession({
        fbu: user.uid,
        cid: contract.id,
      });
      window.location.href = result.data.url;
    } catch (error) {
      globalActions.setSnackbarMessage({ message: `${t('unexpected_error')} ${error}`, severity: 'error' });
    } finally {
      setPageStatus('done');
    }
  };

  const showGoToAcquirerForm = () => user
    && (user.uid === contract.sharedTo || (user.uid === contract.owner && contract.bothPartiesPresent === true))
    && ['contractSharingAccepted', 'ownerSubmittedFirstForm'].includes(contract.state);
  const showGoToOwnerCompleteSFForm = () => user
    && user.uid === contract.owner
    && contract.state === 'acquierSubmittedForm';
  const showContractPdf = () => user
    && user.uid === contract.owner
    && contract.state === 'sellerSaleFormalitiesCompleted';
  const showGoToContractPdf = () => contract.state === 'contractSuccessfullyGenerated';
  const showModifySaleForm = () => user
    && user.uid === contract.owner
    && !['sellerSaleFormalitiesCompleted', 'contractSuccessfullyGenerated'].includes(contract.state);
  const showModifyAcquirerForm = () => user
    && (user.uid === contract.sharedTo || (user.uid === contract.owner && contract.bothPartiesPresent === true))
    && !['sellerSaleFormalitiesCompleted', 'contractSuccessfullyGenerated'].includes(contract.state);
  const showModifySellerSaleFormalitiesForm = () => user
    && user.uid === contract.owner
    && !['contractSuccessfullyGenerated'].includes(contract.state);
  const showWaitingForInviteInfo = () => user
    && user.uid === contract.owner
    && !contract.bothPartiesPresent
    && ['ownerSubmittedFirstForm'].includes(contract.state);
  const showWaitingForAcquirerInfo = () => user
    && user.uid === contract.owner
    && !contract.bothPartiesPresent
    && ['contractSharingAccepted'].includes(contract.state);

  const [sellerFormExpanded, setSellerFormExpanded] = useState(true);
  const [acquirerFormExpanded, setAcquirerFormExpanded] = useState(true);
  const [formalitiesFormExpanded, setFormalitiesFormExpanded] = useState(true);

  const handleModifySaleForm = () => {
    navigate('/vehicleSale', {
      state: {
        mode: 'edit',
        initialValues: contract.ownerForm.values,
        updatingContractId: contract.id,
      },
    });
  };

  const handleModifyAcquirerForm = () => {
    navigate('acquirerForm', {
      state: {
        mode: 'edit',
        initialValues: contract.acquirerForm.values,
        contract,
      },
    });
  };

  const handleModifySellerSaleFormalitiesForm = () => {
    navigate('saleFormalitiesForm', {
      state: {
        mode: 'edit',
        initialValues: contract.sellerSaleFormalitiesForm.values,
        contract,
      },
    });
  };

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', gap: 2, px: 1, maxWidth: '99vw', mb: 15,
    }}
    >

      <MyCollapse
        theRef={ownerCollapse}
        contract={contract}
        setExpand={setSellerFormExpanded}
        expanded={sellerFormExpanded}
        expandIcon={expandIcon}
        title={t('contract.info_entered_by_seller')}
        form={contract.ownerForm}
        formType="saleForm"
        modifyCondition={showModifySaleForm}
        handleModify={handleModifySaleForm}
        borderColor="secondary.main"
      />

      {contract.acquirerForm && (
        <MyCollapse
          theRef={acquirerCollapse}
          contract={contract}
          setExpand={setAcquirerFormExpanded}
          expanded={acquirerFormExpanded}
          expandIcon={expandIcon}
          title={t('contract.info_entered_by_acquirer')}
          form={contract.acquirerForm}
          formType="buyerForm"
          modifyCondition={showModifyAcquirerForm}
          handleModify={handleModifyAcquirerForm}
          borderColor="link.main"
        />
      )}

      {contract.sellerSaleFormalitiesForm && (
      <MyCollapse
        theRef={formalitiesCollapse}
        contract={contract}
        setExpand={setFormalitiesFormExpanded}
        expanded={formalitiesFormExpanded}
        expandIcon={expandIcon}
        title={t('sellerSaleFormalitiesForm.title')}
        form={contract.sellerSaleFormalitiesForm}
        formType="formalitiesForm"
        modifyCondition={showModifySellerSaleFormalitiesForm}
        handleModify={handleModifySellerSaleFormalitiesForm}
        borderColor="info.main"
      />
      )}
      <Action
        showGoToAcquirerForm={showGoToAcquirerForm}
        goToAcquirerForm={goToAcquirerForm}
        showGoToOwnerCompleteSFForm={showGoToOwnerCompleteSFForm}
        goToOwnerCompleteSFForm={goToOwnerCompleteSFForm}
        showContractPdf={showContractPdf}
        handleStripeCheckout={handleStripeCheckout}
        pageStatus={pageStatus}
        showGoToContractPdf={showGoToContractPdf}
        handleGoToContract={handleGoToContract}
        contract={contract}
        setContract={setContract}
        showWaitingForInviteInfo={showWaitingForInviteInfo}
        showWaitingForAcquirerInfo={showWaitingForAcquirerInfo}
        t={t}
        ownerCollapse={ownerCollapse}
        acquirerCollapse={acquirerCollapse}
        formalitiesCollapse={formalitiesCollapse}
      />
    </Box>
  );
}

function Action({
  showGoToAcquirerForm, goToAcquirerForm, showGoToOwnerCompleteSFForm,
  goToOwnerCompleteSFForm, showContractPdf, handleStripeCheckout, pageStatus,
  showGoToContractPdf, handleGoToContract, contract, setContract, showWaitingForInviteInfo,
  showWaitingForAcquirerInfo, t, ownerCollapse, acquirerCollapse, formalitiesCollapse,
}) {
  const steps = [t('contract.info_entered_by_seller'),
    t('contract.info_entered_by_acquirer'),
    t('sellerSaleFormalitiesForm.title')];
  let activeStep = 0;
  if (contract.sellerSaleFormalitiesForm) {
    activeStep = 2;
  } else if (contract.acquirerForm) {
    activeStep = 1;
  }

  const completed = [activeStep >= 0, activeStep >= 1, activeStep >= 2];

  const scrollToRefWithOffset = (ref, offset) => {
    if (ref && ref.current) {
      const top = ref.current.getBoundingClientRect().top + window.pageYOffset - offset;
      window.scrollTo({ top, behavior: 'smooth' });
    }
  };

  const handleStep = (step) => () => {
    const offset = 80;
    if (step === 0) {
      scrollToRefWithOffset(ownerCollapse, offset);
    } else if (step === 1) {
      scrollToRefWithOffset(acquirerCollapse, offset);
    } else if (step === 2) {
      scrollToRefWithOffset(formalitiesCollapse, offset);
    }
  };

  const stepColor = (index) => {
    if (index === 0) {
      return 'secondary.main';
    }
    if (index === 1) {
      return 'link.main';
    }
    return 'info.main';
  };

  return (
    <Paper
      elevation={3}
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        zIndex: 9999,
        boxShadow: '0px -2px 10px rgba(0,0,0,0.1)',
        pb: 2,
      }}
    >
      <Stepper nonLinear activeStep={activeStep} sx={{ px: 1, py: 2 }}>
        {steps.map((label, index) => (
          <Step
            key={label}
            completed={completed[index]}
            sx={{
              '& .MuiStepLabel-root .Mui-completed': {
                color: stepColor(index),
              },
            }}
          >
            <StepButton
              color="inherit"
              onClick={handleStep(index)}
            >
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      {showGoToAcquirerForm() && (
      <Button
        color="primary"
        aria-label="completeAcquirerForm"
        onClick={goToAcquirerForm}
        variant="contained"
      >
        {t('contract.complete_acquirer_part')}
        <ArrowForwardIosIcon />
      </Button>
      )}
      {showGoToOwnerCompleteSFForm() && (
      <Button
        variant="contained"
        color="primary"
        aria-label="completeFormalities"
        onClick={goToOwnerCompleteSFForm}
      >
        {t('contract.finish_sale_formalities')}
        <ArrowForwardIosIcon />
      </Button>
      )}
      {showContractPdf() && (
      <>
        <Typography sx={{ fontWeigth: 'bold', mb: 1 }} variant="h6">
          Votre contrat est prêt à être généré au format PDF
        </Typography>
        <LoadingButton
          loading={pageStatus === 'loading'}
          color="primary"
          aria-label="generatepdf"
          onClick={handleStripeCheckout}
          variant="contained"
          endIcon={<ArrowForwardIosIcon />}
        >
          <Box sx={{
            display: 'flex', flexDirection: 'column', mx: 3, my: 2,
          }}
          >

            <Typography>
              {t('continue_to_payment')}
            </Typography>
          </Box>
        </LoadingButton>
      </>
      )}
      {showGoToContractPdf() && (
      <Button
        color="primary"
        aria-label="generatepdf"
        onClick={handleGoToContract}
        variant="contained"
      >
        {t('state_display.vehicleSalePrivateIndividuals.contractSuccessfullyGenerated')}
        <ArrowForwardIosIcon />
      </Button>
      )}
      {showWaitingForInviteInfo() && (
      <WaitingForInviteInfo contract={contract} setContract={setContract} />
      )}
      {showWaitingForAcquirerInfo() && (
      <Typography sx={{ border: 1, borderRadius: 5, py: 3 }}>
        {t(
          'state_display.vehicleSalePrivateIndividuals.invitation_accepted_waiting',
        )}
      </Typography>
      )}
    </Paper>
  );
}

function MyCollapse({
  setExpand, expanded, expandIcon, form, title, borderColor, theRef,
  formType, modifyCondition = () => false, handleModify = () => {}, contract,
}) {
  const { t } = useTranslation();

  return (
    <Paper
      ref={theRef}
      sx={{
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        justifyContent: 'center',
        alignItems: 'center',
        border: 2,
        borderColor,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: 1,
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={() => setExpand(!expanded)}
      >
        <Typography variant="h6">
          {title}
        </Typography>
        <Button
          size="small"
          color="inherit"
          variant="outlined"
          endIcon={expandIcon(expanded)}
          sx={{ fontSize: 10, width: 102 }}
        >
          {expanded ? t('collapse') : t('expand')}
        </Button>

      </Box>

      <Collapse
        in={expanded}
      >
        <Box sx={{
          display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center',
        }}
        >
          <ReadonlyView formType={formType} initialFormState={form.values} contract={contract} />
          {modifyCondition() && (
          <Button size="small" variant="outlined" onClick={handleModify}>
            {t('modify')}
          </Button>
          )}
          <Button
            size="small"
            color="inherit"
            variant="outlined"
            endIcon={expandIcon(expanded)}
            sx={{ fontSize: 10, width: 102, alignSelf: 'flex-end' }}
            onClick={() => setExpand(!expanded)}
          >
            {expanded ? t('collapse') : t('expand')}
          </Button>
        </Box>
      </Collapse>
    </Paper>
  );
}

function ReadonlyView({ initialFormState, formType, contract }) {
  const prepareFormValues = (values) => {
    if (Array.isArray(values)) {
      return values.map((item) => prepareFormValues(item));
    } if (typeof values === 'object' && values !== null) {
      // Check if object is a Firestore timestamp-like object
      if ('seconds' in values && (values.nanoseconds || values.nanoseconds === 0)) {
        // Convert to Date object; alternatively, you can use moment.unix(values.seconds)
        return moment.unix(values.seconds);
      }

      // If not a timestamp object, recursively process each property
      const deserializedValues = {};
      Object.keys(values).forEach((key) => {
        deserializedValues[key] = prepareFormValues(values[key]);
      });
      return deserializedValues;
    }
    return values;
  };
  if (formType === 'saleForm') {
    return (
      <SharedSaleForm
        mode="readonly"
        initialValues={prepareFormValues(initialFormState)}
      />
    );
  } if (formType === 'formalitiesForm') {
    return (
      <SharedSellerSaleFormalitiesForm
        mode="readonly"
        contract={contract}
        initialValues={prepareFormValues(initialFormState)}
      />
    );
  }
  return (
    <SharedBuyerForm
      mode="readonly"
      contract={contract}
      initialValues={prepareFormValues(initialFormState)}
    />
  );
}
