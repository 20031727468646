import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useSigninCheck } from 'reactfire';
import { useTranslation } from 'react-i18next';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import { menuPaths, getWindowDimensions, noMenuPaths } from 'components/utils';
import AccountBox from '@mui/icons-material/AccountBox';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import useMenu from 'menu-actions/useMenu';
import Divider from '@mui/material/Divider';
import { useSignIn } from 'useSignIn/SingInContext';
import useGlobal from 'global-state/store';
import AlphaDialog from './AlphaDialog';
import VerifyEmailDialog from './VerifyEmailDialog';

export default function TopToolbar() {
  const { data: signInCheckResult } = useSigninCheck();
  const { menuActions } = useMenu();
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const { openSignin } = useSignIn();
  const location = useLocation();
  const [isSmallScreen, setIsSmallScreen] = useState(
    getWindowDimensions().width < 600,
  );
  const [globalState] = useGlobal();
  const [alphaDialogOpen, setAlphaDialogOpen] = useState(false);
  const navigate = useNavigate();

  function displayMenu() {
    return Object.keys(menuActions).length > 0;
  }

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  function displayLanguageToggle() {
    return !isSmallScreen;
  }

  const handleAlphaClick = () => {
    if (!(signInCheckResult?.signedIn === true)) {
      openSignin(() => async () => {
        setAlphaDialogOpen(true);
      });
    } else if (!globalState.roles.includes('alpha')) {
      setAlphaDialogOpen(true);
    } else {
      navigate('/alpha');
    }
  };

  const showEmailVerify = () => signInCheckResult?.signedIn === true
    && signInCheckResult?.user.emailVerified === false;

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <LogoOrBack />
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          flexWrap: 'wrap',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={handleAlphaClick}
        >
          Alpha
        </Button>
        {signInCheckResult?.signedIn === true && (
          <Typography variant="h6">
            <IconButton
              aria-label="account"
              component={Link}
              to="/account"
              sx={{ p: 0, fontSize: 37 }}
              color="primary"
            >
              <AccountBox fontSize="inherit" />
            </IconButton>
          </Typography>
        )}
        {/* {!(signInCheckResult?.signedIn === true) && (
          <Button
            variant="outlined"
            color="primary"
            size="small"
            component={Link}
            onClick={() => {
              openSignin();
            }}
            startIcon={<AccountCircle />}
          >
            {t('signin.login')}
          </Button>
        )} */}
        {displayMenu() && (
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={menuOpen ? 'long-menu' : undefined}
            aria-expanded={menuOpen ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleMenuClick}
          >
            <MoreVertIcon />
          </IconButton>
        )}
        <MyMenu
          anchorEl={anchorEl}
          handleMenuClose={handleMenuClose}
          menuOpen={menuOpen}
          actions={menuActions}
        />
      </Box>
      <AlphaDialog alphaDialogOpen={alphaDialogOpen} setAlphaDialogOpen={setAlphaDialogOpen} />
      <VerifyEmailDialog dialogOpen={showEmailVerify() && !['/', '/account'].includes(location.pathname)} />
    </Box>
  );
}

function MyMenu(props) {
  const {
    anchorEl, handleMenuClose, menuOpen, actions,
  } = props;

  return (
    <Menu
      id="long-menu"
      MenuListProps={{
        'aria-labelledby': 'long-button',
      }}
      anchorEl={anchorEl}
      open={menuOpen}
      onClose={handleMenuClose}
    >
      {Object.entries(actions).map((action) => (
        <MyMenuItem key={action[0]} title={action[0]} callback={action[1]} handleMenuClose={handleMenuClose}>
          {action[0]}
        </MyMenuItem>
      ))}
    </Menu>
  );
}

function MyMenuItem(props) {
  const { title, callback, handleMenuClose } = props;
  if (title.includes('divider')) {
    return <Divider />;
  }
  return (
    <MenuItem onClick={() => { callback(); handleMenuClose(); }}>
      {title}
    </MenuItem>
  );
}

function LogoOrBack(props) {
  const location = useLocation();
  const { t } = useTranslation();
  const { returnAction, setreturnAction } = useMenu();
  const navigate = useNavigate();

  useEffect(() => {
    setreturnAction(() => () => navigate(-1));
  }, [location, navigate, setreturnAction]);

  if (menuPaths(location) || noMenuPaths(location)) {
    return (
      <Box />
    );
  }
  return (
    <Button
      sx={{ color: 'text.primary' }}
      startIcon={<ChevronLeft />}
      onClick={returnAction}
    >
      {t('back')}
    </Button>
  );
}
