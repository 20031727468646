import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useFirestore, useFunctions, useSigninCheck } from 'reactfire';
import { doc, getDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import useGlobal from 'global-state/store';
import { useTranslation } from 'react-i18next';
import LoadingPage from 'components/LoadingPage';
import { Box, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSignIn } from 'useSignIn/SingInContext';
import { getAnalytics, logEvent } from 'firebase/analytics';

function AcceptSaleInvitation() {
  const [searchParams] = useSearchParams();
  const sharingId = searchParams.get('sharingId');
  const firestore = useFirestore();
  const functions = useFunctions();
  functions.region = 'europe-west1';
  const [pageStatus, setPageStatus] = useState('initial');
  const [, globalActions] = useGlobal();
  const [contractShare, setContractShare] = useState(null);
  const { t } = useTranslation();
  const [loadingCall, setLoadingCall] = useState(false);
  const navigate = useNavigate();
  const analytics = getAnalytics();
  const { data: signInCheckResult } = useSigninCheck();
  const { openSignin } = useSignIn();

  const acceptSaleInvitation = async () => {
    setLoadingCall(true);
    try {
      const acceptSaleInvitationCall = httpsCallable(functions, 'acceptVehicleSaleInvitationCall');
      await acceptSaleInvitationCall({ sharingId });
      setLoadingCall(false);
      globalActions.setSnackbarMessage({
        message: `${t('added_to_my_contracts')}`,
        severity: 'success',
        displayDuration: 3000,
      });
      navigate('/myContracts');
      logEvent(analytics, 'vehicle-sale-contract-accepted', {
        appName: 'Autonoom Web',
      });
    } catch (err) {
      setLoadingCall(false);
      globalActions.setSnackbarMessage({ message: `${t('unexpected_error')} ${err.message}`, severity: 'error' });
      throw err;
    }
  };

  const acceptSaleInvitationCheck = async () => {
    if (signInCheckResult?.signedIn !== true) {
      openSignin(() => () => acceptSaleInvitation());
    } else {
      acceptSaleInvitation();
    }
  };

  const fetchContractShare = useCallback(async () => {
    const docRef = doc(firestore, 'contractShares', sharingId);
    const snapShot = await getDoc(docRef);
    return snapShot.data();
  }, [firestore, sharingId]);

  const load = useCallback(async () => {
    try {
      if (!sharingId) {
        throw Error('Missing sharingId parameter');
      }
      setPageStatus('loading');
      const fetchedShare = await fetchContractShare();
      if (!fetchedShare) {
        globalActions.setSnackbarMessage({ message: `${t('saleInvitation.shareDoesNotExist')}`, severity: 'warning' });
        navigate('/myContracts');
        return;
      }
      setContractShare(fetchedShare);
      setPageStatus('loaded');
    } catch (err) {
      globalActions.setSnackbarMessage({ message: `${t('unexpected_error')} ${err.message}`, severity: 'error' });
      throw err;
    }
  }, [fetchContractShare, globalActions, navigate, sharingId, t]);

  useEffect(() => {
    if (pageStatus === 'initial') {
      load();
    }
  }, [load, pageStatus]);

  if (pageStatus !== 'loaded') {
    return <LoadingPage />;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Typography variant="h6">{contractShare.name}</Typography>
      <Typography>
        {t('saleInvitation.invite_text', {
          sellerName: contractShare.from,
        })}

      </Typography>
      <LoadingButton
        loading={loadingCall}
        onClick={() => acceptSaleInvitationCheck()}
        variant="contained"
      >
        {t('saleInvitation.acceptButton')}
      </LoadingButton>
    </Box>
  );
}

export default AcceptSaleInvitation;
