import React, {
  useCallback, useEffect, useState,
} from 'react';
import Box from '@mui/material/Box';
import {
  Button, Fab, IconButton, Typography,
} from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useTranslation } from 'react-i18next';
import useGlobal from 'global-state/store';
import { getDownloadURL, ref } from 'firebase/storage';
import { useStorage } from 'reactfire';
import DeleteIcon from '@mui/icons-material/Delete';
import FilePreviewDialog from './FilePreviewDialog';
import { addWatermarkToImage, addWatermarkToPdf } from './applyWaterMarkToFile.js';
import { resizeImage } from './resizeFileSize';

export default function InputFileUpload({
  formik, field, label, accept, maxFiles = 1, maxFileSize = 2, fileBaseName, isReadOnly,
}) {
  const storage = useStorage();
  const { t } = useTranslation();
  const MAX_FILE_SIZE_IN_MO = maxFileSize;
  const MAX_FILE_SIZE = MAX_FILE_SIZE_IN_MO * 1024 * 1024;
  const [, globalActions] = useGlobal();
  const [preparedFiles, setPreparedFiles] = useState([]);
  const [filePreviews, setFilePreviews] = useState([]);
  const [openLargeView, setOpenLargeView] = useState(false);
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [initialState, setInitialState] = useState(true);
  const isStillAdding = filePreviews.length < maxFiles;

  const handleClickOpen = (index) => {
    setCurrentFileIndex(index);
    setOpenLargeView(true);
  };

  function getNestedValue(obj, path) {
    // This regular expression splits the path at dots and also captures array indices as separate paths
    const keys = path.match(/[^.\[\]]+|\[\d+\]/g);
    return keys.reduce((currentObject, originalKey) => {
      let key = originalKey;
      // Check if the key is an array index and strip brackets if it is
      if (key.includes('[')) {
        key = key.replace(/\[(\d+)\]/, '$1'); // Remove brackets and capture the index number
        return currentObject ? currentObject[parseInt(key, 10)] : undefined;
      }
      return currentObject ? currentObject[key] : undefined;
    }, obj);
  }

  const fieldError = getNestedValue(formik.errors, field);
  const fieldTouched = getNestedValue(formik.touched, field);

  const initializeFilePreviews = useCallback(async (files) => {
    const previews = await Promise.all(files.map(async (file) => {
      let url;
      if (file.file instanceof File) {
        url = URL.createObjectURL(file.file);
      } else {
        url = await getDownloadURL(ref(storage, file.storagePath));
      }
      return {
        id: `${file.name}_${Date.now()}_${Math.random()}`,
        name: file.name,
        url,
        isImage: /\.(jpe?g|png)$/i.test(file.name),
      };
    }));
    setFilePreviews(previews);
  }, [storage]);

  useEffect(() => {
    initializeFilePreviews(preparedFiles);
  }, [initializeFilePreviews, preparedFiles]);

  useEffect(() => {
    const filesFromForm = getNestedValue(formik.values, field);
    if (initialState === true && filesFromForm && filesFromForm.length > 0 && filePreviews.length === 0) {
      initializeFilePreviews(filesFromForm);
      setInitialState(false);
    } else if (initialState === true) {
      setInitialState(false);
    }
  }, [field, filePreviews.length, formik.values, initialState, initializeFilePreviews]);

  const handleFileChange = async (event) => {
    const originalFiles = Array.from(event.currentTarget.files);
    const oversizedPDFs = [];

    const sizeReducedFiles = await Promise.all(originalFiles.map(async (file) => {
      if (file.size > MAX_FILE_SIZE) {
        if (file.type === 'application/pdf') {
          oversizedPDFs.push(file);
          return file;
        }
        return resizeImage(file, 1920, 1080, MAX_FILE_SIZE);
      }
      return file;
    }));

    const files = sizeReducedFiles.map((file, index) => {
      const fileExtension = file.name.split('.').pop();
      const newFileName = `${fileBaseName}-${index + 1}.${fileExtension}`;
      return new File([file], newFileName, { type: file.type, lastModified: file.lastModified });
    });

    const oversizedFiles = originalFiles.filter((file) => file.size > MAX_FILE_SIZE);

    if (oversizedPDFs.length > 0) {
      globalActions.setSnackbarMessage({
        message: t('file_size_error', { sizeInMo: MAX_FILE_SIZE_IN_MO }),
        severity: 'error',
      });
      return;
    }

    if (oversizedFiles.length > 0) {
      globalActions.setSnackbarMessage({
        message: t('image_size_warning', { sizeInMo: MAX_FILE_SIZE_IN_MO }),
        severity: 'warning',
      });
    }

    let newFiles;
    if (isStillAdding) {
      const remainingSlots = maxFiles - filePreviews.length;
      newFiles = files.slice(0, remainingSlots);
    } else {
      newFiles = files.slice(0, maxFiles);
    }

    const processedFiles = await Promise.all(newFiles.map((file) => {
      if (/\.(jpe?g|png)$/i.test(file.name)) {
        return addWatermarkToImage(file);
      }
      return addWatermarkToPdf(file);
    }));

    const finalFiles = Array.from(processedFiles).map((file) => ({
      file,
      name: file.name,
      id: `${file.name}_${Date.now()}_${Math.random()}`,
      isImage: /\.(jpe?g|png)$/i.test(file.name),
      url: file.isImage ? URL.createObjectURL(file.file) : null,
    }));

    if (filePreviews.length >= maxFiles) {
      setPreparedFiles(finalFiles);
      formik.setFieldValue(field, finalFiles.map((f) => f.file));
    } else {
      const updatedFiles = [...preparedFiles, ...finalFiles];
      setPreparedFiles(updatedFiles);
      formik.setFieldValue(field, updatedFiles.map((f) => f.file));
    }
  };

  const handleRemoveFile = (index) => {
    const updatedFilePreviews = filePreviews.filter((_, i) => i !== index);
    const updatedPreparedFiles = preparedFiles.filter((_, i) => i !== index);

    setFilePreviews(updatedFilePreviews);
    setPreparedFiles(updatedPreparedFiles);
    formik.setFieldValue(field, updatedPreparedFiles.map((f) => f.file));
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 1,
      border: 1,
      borderRadius: 1,
      p: 1,
      borderColor: fieldTouched && fieldError ? '#db5353' : 'gray.main',
    }}
    >
      <Box
        id={`container_${field}`}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: { xs: 'center', md: 'space-between' },
          width: '100%',
          gap: { xs: 1, md: 3 },
          flexWrap: 'wrap',
        }}
      >
        <Typography sx={{
          color: fieldTouched && fieldError ? '#db5353' : 'text.primary',
          maxWidth: '75%',
        }}
        >
          {label}

        </Typography>
        <Button
          size="small"
          variant="contained"
          component="label"
          startIcon={<UploadFileIcon />}
          disabled={isReadOnly}
        >
          {`${isStillAdding ? t('add') : t('replace')} ${filePreviews.length} / ${maxFiles}`}
          <input
            name={field}
            type="file"
            hidden
            onChange={handleFileChange}
            accept={accept || ''}
            multiple
          />
        </Button>
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 1,
      }}
      >
        {filePreviews.map((file, index) => (
          <Box
            key={file.id}
            sx={{
              gap: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              cursor: 'pointer',
              position: 'relative',
            }}
            onClick={() => handleClickOpen(index)}
          >
            {file.isImage ? (
              <img src={file.url} alt={file.name} style={{ maxWidth: '100px', maxHeight: '100px' }} />
            ) : (
              <PictureAsPdfIcon />
            )}
            <Typography variant="caption">{file.name}</Typography>
            {!isReadOnly && (
              <Fab
                aria-label="delete"
                color="inherit"
                variant="contained"
                onClick={(e) => {
                  e.stopPropagation();
                  handleRemoveFile(index);
                }}
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 16,
                  p: 0,
                  width: 34,
                  height: 20,
                }}
              >
                <DeleteIcon sx={{ fontSize: 16, m: 0 }} />
              </Fab>
            )}
          </Box>
        ))}
      </Box>
      {fieldTouched && fieldError && (
        <Typography color="error">{fieldError}</Typography>
      )}
      <FilePreviewDialog
        openDialog={openLargeView}
        setOpenDialog={setOpenLargeView}
        filePreviews={filePreviews}
        currentFileIndex={currentFileIndex}
        setCurrentFileIndex={setCurrentFileIndex}
      />
    </Box>
  );
}
