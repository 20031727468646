import React from 'react';
import Button from '@mui/material/Button';
import {
  Box,
  Dialog, DialogActions, DialogContent, Typography,
} from '@mui/material';
import { useUser } from 'reactfire';
import { useTranslation } from 'react-i18next';
import { sendEmailVerification } from 'firebase/auth';
import TaskAlt from '@mui/icons-material/TaskAlt';

export default function VerifyEmailDialog({ dialogOpen }) {
  const { t } = useTranslation();
  const { data: user } = useUser();
  const [emailSent, setemailSent] = React.useState(false);

  const sendEmail = () => {
    if (user) {
      sendEmailVerification(
        user,
        { url: `${window.location.protocol}//${window.location.host}?confirm_email=true` },
      );
      setemailSent(true);
    }
  };
  return (
    <Dialog open={dialogOpen} sx={{}}>
      <DialogContent sx={{
        display: 'flex', flexDirection: 'column', gap: 3, alignItems: 'center',
      }}
      >
        <Box sx={{
          display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 3, alignItems: 'center',
        }}
        >
          <TaskAlt sx={{ fontSize: 40, color: 'secondary' }} />
          <Typography component="span" variant="h6">{t('signin.verify_email')}</Typography>
        </Box>

        <Typography component="span" variant="caption">
          {t('signin.verify_email_hint')}
          {' '}
          <b>contact@autonoom.fr</b>
        </Typography>

      </DialogContent>
      <DialogActions>
        {user && (
        <Button
          variant="contained"
          disabled={emailSent}
          color="secondary"
          onClick={() => sendEmail()}
        >
          {t('signin.re_send_email')}
        </Button>
        )}
      </DialogActions>
    </Dialog>

  );
}
