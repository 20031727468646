import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import useGlobal from 'global-state/store';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import { useFunctions } from 'reactfire';
import { httpsCallable } from 'firebase/functions';
import { Formik } from 'formik';

export default function ValidateUserEmail() {
  const { t } = useTranslation();
  const functions = useFunctions();
  functions.region = 'europe-west1';
  const [activating, setactivating] = React.useState(false);
  const [, globalActions] = useGlobal();

  const verifyUserEmail = httpsCallable(functions, 'verifyUserEmail');

  return (
    <Formik
      initialValues={{ emailOfuser: '' }}
      validate={(values) => {
        const errors = {};
        if (!values.emailOfuser) {
          errors.emailOfuser = t('validations.required');
        } else if (
          !/^(.+)@(.+)$/i.test(values.emailOfuser)
        ) {
          errors.emailOfuser = 'format invalide';
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          setactivating(true);
          await verifyUserEmail({ userEmail: values.emailOfuser });
          globalActions.setSnackbarMessage({ message: 'Succès', severity: 'success', displayDuration: 2000 });
        } catch (error) {
          if (error.message === 'INTERNAL') {
            globalActions.setSnackbarMessage({ message: t('unexpected_error'), severity: 'error' });
          } else {
            globalActions.setSnackbarMessage({ message: error.message, severity: 'error' });
          }
        } finally {
          setSubmitting(false);
          setactivating(false);
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <Paper
          component="form"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
            padding: 3,
          }}
          onSubmit={handleSubmit}
        >
          <Typography component="span" variant="h6">
            {'Valider l\'email de l\'utilisateur'}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'flex-start',
              gap: 1,
            }}
          >
            <TextField
              size="small"
              fullWidth
              name="emailOfuser"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.emailOfuser}
              label="Email de l'utilisateur"
              error={touched.emailOfuser && Boolean(errors.emailOfuser)}
              helperText={errors.emailOfuser && touched.emailOfuser && errors.emailOfuser}
            />
            <LoadingButton
              size="medium"
              loading={activating}
              variant="contained"
              color="secondary"
              type="submit"
              disabled={isSubmitting}
            >
              valider
            </LoadingButton>
          </Box>
        </Paper>
      )}
    </Formik>
  );
}
