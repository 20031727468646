import React, { useEffect, useState } from 'react';
import {
  Box, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUser } from 'reactfire';
import VehicleSaleContract from './VehicleSaleContract';

export default function ContractPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: user } = useUser();
  const location = useLocation();
  const [contract, setContract] = useState(location.state?.contract);

  useEffect(() => {
    if (!contract) {
      navigate('/');
    }
  }, [navigate, contract]);

  if (!contract) {
    return null;
  }

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2,
    }}
    >
      <Typography variant="h6" sx={{ maxWidth: '80vw' }}>
        {t('contract.overview')}
      </Typography>
      {user && (
        <VehicleSaleContract contract={contract} user={user} setContract={setContract} />
      )}
    </Box>
  );
}
