import React, { useCallback, useEffect } from 'react';
import {
  Dialog, DialogContent,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  useNavigate,
  useLocation,
  Link,
} from 'react-router-dom';
import {
  useSigninCheck,
  useFirebaseApp,
} from 'reactfire';
import useGlobal from 'global-state/store';
import {
  getAuth, GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword,
  createUserWithEmailAndPassword, sendEmailVerification,
} from 'firebase/auth';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { useSignIn } from 'useSignIn/SingInContext';
import SignIn from './SignIn';

export default function SignInDialog() {
  const { t } = useTranslation();
  const { data: signInCheckResult } = useSigninCheck();
  const navigate = useNavigate();
  const [, globalActions] = useGlobal();
  const location = useLocation();
  const app = useFirebaseApp();
  const auth = getAuth(app);
  const analytics = getAnalytics();
  const provider = new GoogleAuthProvider();
  const {
    signInDialogOpen, setSignInDialogOpen, signinCallback,
  } = useSignIn();

  const ourSignInWithPopup = () => signInWithPopup(auth, provider);

  const closeSignin = useCallback(() => {
    setSignInDialogOpen(false);
  }, [setSignInDialogOpen]);

  useEffect(() => {
    if (signInDialogOpen && signInCheckResult?.signedIn === true) {
      closeSignin();
      signinCallback();
    }
  }, [closeSignin, signInCheckResult?.signedIn, signInDialogOpen, signinCallback]);

  return (
    <Dialog
      fullWidth
      open={signInDialogOpen}
      onClose={() => setSignInDialogOpen(false)}
    >
      <DialogContent sx={{ px: { xs: 1, sm: 3 }, py: 3, backgroundColor: 'lightGray.main' }}>
        <SignIn
          t={t}
          globalActions={globalActions}
          navigate={navigate}
          location={location}
          Link={Link}
          auth={auth}
          analytics={analytics}
          gtcuCookieName="agreed-to-general-terms-digitank-tanker-trucks"
          ourSignInWithPopup={ourSignInWithPopup}
          signInWithEmailAndPassword={signInWithEmailAndPassword}
          createUserWithEmailAndPassword={createUserWithEmailAndPassword}
          logEvent={logEvent}
          sendEmailVerification={sendEmailVerification}
          signinCallback={() => {
            globalActions.setUserStatus('initial');
            signinCallback();
          }}
          closeSignin={closeSignin}
        />
      </DialogContent>
    </Dialog>
  );
}
