import { LoadingButton } from '@mui/lab';
import {
  Box, Button, Dialog, DialogActions, DialogContent, TextField, Typography,
} from '@mui/material';
import emailTemplate from 'components/emails/EmailTemplate';
import {
  collection, doc,
  writeBatch,
} from 'firebase/firestore';
import useGlobal from 'global-state/store';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFirestore } from 'reactfire';

export default function WaitingForInviteInfo({ contract, setContract }) {
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [newEmail, setNewEmail] = useState(contract.sharedToEmail);
  const [isLoading, setIsLoading] = useState(false);
  const firestore = useFirestore();
  const [, globalActions] = useGlobal();
  const navigate = useNavigate();
  const location = useLocation();

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleUpdateContract = () => {
    const updatedContract = { ...contract, sharedToEmail: newEmail };
    setContract(updatedContract);
    navigate(location.pathname, { state: { contract: updatedContract }, replace: true });
  };

  const handleSaveEmail = async () => {
    setIsLoading(true);

    const batch = writeBatch(firestore);
    const contractDocRef = doc(firestore, 'autonoomContracts', contract.id);
    const mailDocRef = doc(collection(firestore, 'mail'));

    // Update the sharedToEmail property in the contract document
    batch.update(contractDocRef, {
      sharedToEmail: newEmail,
    });

    const baseUrl = window.location.origin;
    const path = '/acceptContractSharingInvitation';
    const queryParams = `?sharingId=${contract.sharingId}`;
    const sharingLink = `${baseUrl}${path}${queryParams}`;

    const messageHtml1 = emailTemplate(` <p>${t('sendsaleForm.sale_invite_email_content', {
      sellerName: contract.ownerForm.values.seller.sellerType === 'individual'
        ? contract.ownerForm.values.seller.individual.firstName + contract.ownerForm.values.seller.individual.lastName
        : contract.ownerForm.values.seller.entity.companyName,
      vehicleName: contract.ownerForm.values.vehicle.model,
      link: ` <p>
      <a href="${sharingLink}"
         style="
           display: inline-block;
           padding: 10px 20px;
           background-color: #14c7bf;
           color: white;
           text-decoration: none;
           border-radius: 5px;
           font-weight: bold;
         ">
          Accepter de compléter le contrat
      </a>
    </p>`,
      linkText: sharingLink,
    })}</p>`);

    batch.set(mailDocRef, {
      to: newEmail,
      message: {
        subject: t('sendsaleForm.sale_invite_email_title'),
        html: messageHtml1,
      },
    });

    try {
      // Commit the batch
      await batch.commit();
      setIsLoading(false);
      setDialogOpen(false);
      globalActions.setSnackbarMessage({ message: t('codes.success'), severity: 'success', displayDuration: 2000 });
      handleUpdateContract();
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };

  return (
    <Box sx={{
      border: 1, borderRadius: 5, p: 3, display: 'flex', flexDirection: 'column', gap: 2,
    }}
    >
      <Typography>
        {t(
          'state_display.vehicleSalePrivateIndividuals.invitation_sent_waiting',
          { buyerEmail: contract.sharedToEmail },
        )}
      </Typography>
      <Typography variant="caption">
        {t('state_display.vehicleSalePrivateIndividuals.invitation_sent_spam_info')}
      </Typography>
      <Button size="small" sx={{ alignSelf: 'center' }} variant="outlined" onClick={handleOpenDialog}>
        {t('edit_email')}
      </Button>
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogContent>
          <TextField
            autoFocus
            label={t('email')}
            type="email"
            fullWidth
            value={newEmail}
            onChange={(e) => setNewEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            onClick={handleCloseDialog}
            disabled={!newEmail || newEmail === contract.sharedToEmail}
          >
            {t('cancel')}
          </Button>
          <LoadingButton
            loading={isLoading}
            onClick={handleSaveEmail}
            disabled={!newEmail || newEmail === contract.sharedToEmail}
          >
            {t('continue')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
